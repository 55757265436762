//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'

export default {
  name: 'Slider',
  props: {
    slides: {
      type: Array,
      required: true,
    },
    sliderId: {
      type: String,
      required: false,
      default: () => 'slider',
    },
    options: {
      type: Object,
      required: false,
      default: () => {},
    },
    separateNavs: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      slider: null,
    }
  },
  mounted() {
    this.slider = new KeenSlider(this.$refs.slider, this.options)
  },
  methods: {
    updateSlider() {
      this.slider = new KeenSlider(this.$refs.slider, this.options)
    },
  },
}
